<template>
  <div class="fyyjqt">
    <!-- 上半部分，罐子的这些 -->
    <div class="container-top fl">
      <div class="box-left">
        <ul>
          <li class="li_item1">一键启停</li>
          <li class="li_item2 fl">
            <div class="item1"></div>
            <div class="item2">醋酐</div>
            <div class="item3">母液</div>
          </li>
          <li class="li_item3 fl">
            <div class="item1">罐选择</div>
            <div class="item2">
              <div class="titile">A罐</div>
              <div class="titile1">B罐</div>
            </div>
            <div class="item2">
              <div class="titile">A罐</div>
              <div class="titile1">B罐</div>
            </div>
          </li>
          <li class="li_item4">正在加料显示</li>
          <li class="li_item3 fl">
            <div class="item4">
              <div class="titile">A</div>
              <div class="titile1">D</div>
            </div>
            <div class="item2">
              <div class="titile">B</div>
              <div class="titile1">E</div>
            </div>
            <div class="item2">
              <div class="titile">C</div>
              <div class="titile1">F</div>
            </div>
          </li>
          <li class="li_item4">定值投料</li>
        </ul>
      </div>
      <div class="box-right">
        <ul class="box-right-top">
          <li class="item1">
            <div class="name">醋酐</div>
            <div class="name">V101a</div>
            <div class="value"
            @click="toCompon(2, 'WT01', 'DB1C', 'WT01','V101a醋酐储罐重量')"
												@dblclick="Cclick(infoList.DB1C.WT01,'WT01', 'DB1C', 'WT01')"
            >{{infoList?infoList.DB1C.WT01:0}} &nbsp;Kg</div>
          </li>
          <li class="item2">
            <div class="name">醋酐</div>
            <div class="name">V101b</div>
            <div class="value"
            @click="toCompon(2, 'WT02', 'DB1C', 'WT02','V101b醋酐储罐重量')"
												@dblclick="Cclick(infoList.DB1C.WT01,'WT02', 'DB1C', 'WT02')"
            >{{infoList?infoList.DB1C.WT02:0}} &nbsp;Kg</div>
          </li>
          <li class="item1">
            <div class="name">母液</div>
            <div class="name">V103a</div>
            <div class="value"
            @click="toCompon(2, 'WT03', 'DB1C', 'WT03','V103a母液储罐重量')"
												@dblclick="Cclick(infoList.DB1C.WT03,'WT03', 'DB1C', 'WT03')"
            >{{infoList?infoList.DB1C.WT03:0}} &nbsp;Kg</div>
          </li>
          <li class="item2">
            <div class="name">母液</div>
            <div class="name">V103b</div>
            <div class="value"
            @click="toCompon(2, 'WT04', 'DB1C', 'WT04','V103b母液储罐重量')"
												@dblclick="Cclick(infoList.DB1C.WT04,'WT04', 'DB1C', 'WT04')"
            >{{infoList?infoList.DB1C.WT04:0}} &nbsp;Kg</div>
          </li>
        </ul>
        <ul class="box-right-bottom">
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB1C', 'TE01','釜温')"
												@dblclick="Cclick(infoList.DB1C.TE01,'TE01', 'DB1C', 'TE01')"
            >{{infoList?infoList.DB1C.TE01:0}}℃</div>
            <div class="name">R107a</div>
            <div class="fname">1#釜趋势</div>
          </li>
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB2C', 'DB2C\TE01','釜温')"
												@dblclick="Cclick(infoList.DB2C.TE01,'TE01', 'DB2C', 'DB2C\TE01')"
            >{{infoList?infoList.DB2C.TE01:0}}℃</div>
            <div class="name">R107b</div>
            <div class="fname">2#釜趋势</div>
          </li>
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB3C', 'DB3C\TE01','釜温')"
												@dblclick="Cclick(infoList.DB3C.TE01,'TE01', 'DB3C', 'DB3C\TE01')"
            >{{infoList?infoList.DB3C.TE01:0}}℃</div>
            <div class="name">R107c</div>
            <div class="fname">3#釜趋势</div>
          </li>
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB4C', 'DB4C\TE01','釜温')"
												@dblclick="Cclick(infoList.DB4C.TE01,'TE01', 'DB4C', 'DB4C\TE01')"
            >{{infoList?infoList.DB4C.TE01:0}}℃</div>
            <div class="name">R107d</div>
            <div class="fname">4#釜趋势</div>
          </li>
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB5C', 'DB5C\TE01','釜温')"
												@dblclick="Cclick(infoList.DB5C.TE01,'TE01', 'DB5C', 'DB5C\TE01')"
            >{{infoList?infoList.DB5C.TE01:0}}℃</div>
            <div class="name">R107e</div>
            <div class="fname">5#釜趋势</div>
          </li>
          <li class="item11">
            <div class="value colorl"
            @click="toCompon(2, 'TE01', 'DB6C', 'DB6C\TE01','釜温')"
												@dblclick="Cclick(infoList.DB6C.TE01,'TE01', 'DB6C', 'DB6C\TE01')"
            >{{infoList?infoList.DB6C.TE01:0}}℃</div>
            <div class="name">R107f</div>
            <div class="fname">6#釜趋势</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-bottom">
      <div class="biaoge-top">
        <ul class="fl">
          <li class="lie1">
            <div class="fl">
              <div class="content1">投料量统计</div>
              <div class="content1">
                <div class="itemtl">醋酐量</div>
                <div class="itemtl">母液量</div>
              </div>
            </div>
            <div class="name_titl">升温确定</div>
            <div class="name_titl">配方选择</div>
          </li>
          <li class="lie2">
            <div class="bg_item2"  @click="toCompon(2, 'AN62', 'DB1V', 'AN62','A釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN62,'AN62', 'DB1V', 'AN62')"
            >{{infoList?infoList.DB1V.AN62:0}}</div>
            <div class="bg_item2"  @click="toCompon(2, 'AN63', 'DB1V', 'AN63','A釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN63,'AN63', 'DB1V', 'AN63')"
            >{{infoList?infoList.DB1V.AN62:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai" :style="{
									background:
									infoList.DB1S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS1==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS1==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS1==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS1==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
          <li class="lie2">
            <div class="bg_item2"  @click="toCompon(2, 'AN64', 'DB1V', 'AN64','B釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN64,'AN64', 'DB1V', 'AN64')"
            >{{infoList?infoList.DB1V.AN64:0}}</div>
            <div class="bg_item2"  @click="toCompon(2, 'AN65', 'DB1V', 'AN65','B釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN65,'AN65', 'DB1V', 'AN65')"
            >{{infoList?infoList.DB1V.AN65:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai" :style="{
									background:
									infoList.DB2S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS2==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS2==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS2==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS2==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
          <li class="lie2">
            <div class="bg_item2"  @click="toCompon(2, 'AN66', 'DB1V', 'AN66','C釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN66,'AN66', 'DB1V', 'AN66')"
            >{{infoList?infoList.DB1V.AN66:0}}</div>
            <div class="bg_item2"  @click="toCompon(2, 'AN67', 'DB1V', 'AN67','C釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN67,'AN67', 'DB1V', 'AN67')"
            >{{infoList?infoList.DB1V.AN67:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai" :style="{
									background:
									infoList.DB3S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS3==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS3==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS3==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS3==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
          <li class="lie2">
            <div class="bg_item2-4"  @click="toCompon(2, 'AN68', 'DB1V', 'AN68','D釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN68,'AN68', 'DB1V', 'AN68')"
            >{{infoList?infoList.DB1V.AN68:0}}</div>
            <div class="bg_item2-4"  @click="toCompon(2, 'AN69', 'DB1V', 'AN69','D釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN69,'AN69', 'DB1V', 'AN69')"
            >{{infoList?infoList.DB1V.AN69:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai-1" :style="{
									background:
									infoList.DB4S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS4==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS4==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS4==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS4==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
          <li class="lie2">
            <div class="bg_item2-4"  @click="toCompon(2, 'AN70', 'DB1V', 'AN70','E釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN70,'AN70', 'DB1V', 'AN70')"
            >{{infoList?infoList.DB1V.AN70:0}}</div>
            <div class="bg_item2-4"  @click="toCompon(2, 'AN71', 'DB1V', 'AN71','E釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN71,'AN71', 'DB1V', 'AN71')"
            >{{infoList?infoList.DB1V.AN71:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai-1" :style="{
									background:
									infoList.DB5S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS5==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS5==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS5==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS5==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
          <li class="lie2">
            <div class="bg_item2-5"  @click="toCompon(2, 'AN72', 'DB1V', 'AN72','F釜加醋酐量')"
												@dblclick="Cclick(infoList.DB1V.AN72,'AN72', 'DB1V', 'AN72')"
            >{{infoList?infoList.DB1V.AN72:0}}</div>
            <div class="bg_item2-5"  @click="toCompon(2, 'AN73', 'DB1V', 'AN73','F釜加母液量')"
												@dblclick="Cclick(infoList.DB1V.AN73,'AN73', 'DB1V', 'AN73')"
            >{{infoList?infoList.DB1V.AN73:0}}</div>
            <div class="bg_item2-2">
              <div class="fangkuai-2" :style="{
									background:
									infoList.DB6S.SW01
									? '#2AFC30'
									: '#cf284b',}"></div>
            </div>
            <div class="bg_item2-3">
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS6==0
									? '#2AFC30'
									: '#cf284b',}"> 小批/</div> 
              <div class="widthl"
              :style="{
									background:
									infoList.DB1C.MS6==1
									? '#2AFC30'
									: '#cf284b',}"> 一次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS6==2
									? '#2AFC30'
									: '#cf284b',}"> 二次/</div> 
              <div class="widthl"  :style="{
									background:
									infoList.DB1C.MS6==3
									? '#2AFC30'
									: '#cf284b',}"> 回收</div> 
            </div>
          </li>
        </ul>
      </div>
      <ul class="biaoge-bottom fl">
        <li class="libo2">参数选择</li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN07', 'DB1V', 'AN07','A釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN07,'AN07', 'DB1V', 'AN07')"
            >{{infoList?infoList.DB1V.AN07:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN13', 'DB1V', 'AN13','A釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN13,'AN13', 'DB1V', 'AN13')"
            >{{infoList?infoList.DB1V.AN13:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN98', 'DB1V', 'AN98','A釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN98,'AN98', 'DB1V', 'AN98')"
            >{{infoList?infoList.DB1V.AN98:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN110', 'DB1V', 'AN110','A釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN110,'AN110', 'DB1V', 'AN110')"
            >{{infoList?infoList.DB1V.AN110:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN32', 'DB1V', 'AN32','A釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN32,'AN32', 'DB1V', 'AN32')"
            >{{infoList?infoList.DB1V.AN32:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN74', 'DB1V', 'AN74','A折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN74,'AN74', 'DB1V', 'AN74')"
            >{{infoList?infoList.DB1V.AN74:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN80', 'DB1V', 'AN80','A折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN80,'AN80', 'DB1V', 'AN80')"
            >{{infoList?infoList.DB1V.AN80:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN104', 'DB1V', 'AN104','A釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN104,'AN104', 'DB1V', 'AN104')"
            >{{infoList?infoList.DB1V.AN104:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN86', 'DB1V', 'AN86','A夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN86,'AN86', 'DB1V', 'AN86')"
            >{{infoList?infoList.DB1V.AN86:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN92', 'DB1V', 'AN92','A釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN86,'AN92', 'DB1V', 'AN92')"
            >{{infoList?infoList.DB1V.AN92:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN08', 'DB1V', 'AN08','B釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN08,'AN08', 'DB1V', 'AN08')"
            >{{infoList?infoList.DB1V.AN08:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN14', 'DB1V', 'AN14','B釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN14,'AN14', 'DB1V', 'AN14')"
            >{{infoList?infoList.DB1V.AN14:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN99', 'DB1V', 'AN99','B釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN99,'AN99', 'DB1V', 'AN99')"
            >{{infoList?infoList.DB1V.AN99:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN111', 'DB1V', 'AN111','B釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN111,'AN111', 'DB1V', 'AN111')"
            >{{infoList?infoList.DB1V.AN111:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN33', 'DB1V', 'AN33','B釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN33,'AN33', 'DB1V', 'AN33')"
            >{{infoList?infoList.DB1V.AN33:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN75', 'DB1V', 'AN75','B折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN75,'AN75', 'DB1V', 'AN75')"
            >{{infoList?infoList.DB1V.AN75:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN81', 'DB1V', 'AN81','B折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN81,'AN81', 'DB1V', 'AN81')"
            >{{infoList?infoList.DB1V.AN81:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN105', 'DB1V', 'AN105','B釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN105,'AN105', 'DB1V', 'AN105')"
            >{{infoList?infoList.DB1V.AN105:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN87', 'DB1V', 'AN87','B夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN87,'AN87', 'DB1V', 'AN87')"
            >{{infoList?infoList.DB1V.AN87:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN93', 'DB1V', 'AN93','B釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN93,'AN93', 'DB1V', 'AN93')"
            >{{infoList?infoList.DB1V.AN93:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN09', 'DB1V', 'AN09','C釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN09,'AN09', 'DB1V', 'AN09')"
            >{{infoList?infoList.DB1V.AN09:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN15', 'DB1V', 'AN15','C釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN15,'AN15', 'DB1V', 'AN15')"
            >{{infoList?infoList.DB1V.AN15:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN100', 'DB1V', 'AN100','C釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN100,'AN100', 'DB1V', 'AN100')"
            >{{infoList?infoList.DB1V.AN100:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN112', 'DB1V', 'AN112','C釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN112,'AN112', 'DB1V', 'AN112')"
            >{{infoList?infoList.DB1V.AN112:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN34', 'DB1V', 'AN34','C釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN34,'AN34', 'DB1V', 'AN34')"
            >{{infoList?infoList.DB1V.AN34:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN76', 'DB1V', 'AN76','C折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN76,'AN76', 'DB1V', 'AN76')"
            >{{infoList?infoList.DB1V.AN76:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN82', 'DB1V', 'AN82','C折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN82,'AN82', 'DB1V', 'AN82')"
            >{{infoList?infoList.DB1V.AN82:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN106', 'DB1V', 'AN106','C釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN106,'AN106', 'DB1V', 'AN106')"
            >{{infoList?infoList.DB1V.AN106:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN88', 'DB1V', 'AN88','C夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN88,'AN88', 'DB1V', 'AN88')"
            >{{infoList?infoList.DB1V.AN88:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN94', 'DB1V', 'AN94','C釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN94,'AN94', 'DB1V', 'AN94')"
            >{{infoList?infoList.DB1V.AN94:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN10', 'DB1V', 'AN10','D釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN10,'AN10', 'DB1V', 'AN10')"
            >{{infoList?infoList.DB1V.AN10:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN16', 'DB1V', 'AN16','D釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN16,'AN16', 'DB1V', 'AN16')"
            >{{infoList?infoList.DB1V.AN16:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN101', 'DB1V', 'AN101','D釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN101,'AN101', 'DB1V', 'AN101')"
            >{{infoList?infoList.DB1V.AN101:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN113', 'DB1V', 'AN113','D釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN113,'AN113', 'DB1V', 'AN113')"
            >{{infoList?infoList.DB1V.AN113:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN35', 'DB1V', 'AN35','D釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN35,'AN35', 'DB1V', 'AN35')"
            >{{infoList?infoList.DB1V.AN35:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN77', 'DB1V', 'AN77','D折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN77,'AN77', 'DB1V', 'AN77')"
            >{{infoList?infoList.DB1V.AN77:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN83', 'DB1V', 'AN83','D折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN83,'AN83', 'DB1V', 'AN83')"
            >{{infoList?infoList.DB1V.AN83:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN107', 'DB1V', 'AN107','D釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN107,'AN107', 'DB1V', 'AN107')"
            >{{infoList?infoList.DB1V.AN107:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN89', 'DB1V', 'AN89','D夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN89,'AN89', 'DB1V', 'AN89')"
            >{{infoList?infoList.DB1V.AN89:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN95', 'DB1V', 'AN95','D釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN95,'AN95', 'DB1V', 'AN95')"
            >{{infoList?infoList.DB1V.AN95:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN11', 'DB1V', 'AN11','E釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN11,'AN11', 'DB1V', 'AN11')"
            >{{infoList?infoList.DB1V.AN11:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN17', 'DB1V', 'AN17','E釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN17,'AN17', 'DB1V', 'AN17')"
            >{{infoList?infoList.DB1V.AN17:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN102', 'DB1V', 'AN102','E釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN102,'AN102', 'DB1V', 'AN102')"
            >{{infoList?infoList.DB1V.AN102:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN114', 'DB1V', 'AN114','E釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN114,'AN114', 'DB1V', 'AN114')"
            >{{infoList?infoList.DB1V.AN114:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN36', 'DB1V', 'AN36','E釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN36,'AN36', 'DB1V', 'AN36')"
            >{{infoList?infoList.DB1V.AN36:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN78', 'DB1V', 'AN78','E折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN78,'AN78', 'DB1V', 'AN78')"
            >{{infoList?infoList.DB1V.AN78:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN84', 'DB1V', 'AN84','E折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN84,'AN84', 'DB1V', 'AN84')"
            >{{infoList?infoList.DB1V.AN84:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN108', 'DB1V', 'AN108','E釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN108,'AN108', 'DB1V', 'AN108')"
            >{{infoList?infoList.DB1V.AN108:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN90', 'DB1V', 'AN90','E夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN90,'AN90', 'DB1V', 'AN90')"
            >{{infoList?infoList.DB1V.AN90:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN96', 'DB1V', 'AN96','E釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN96,'AN96', 'DB1V', 'AN96')"
            >{{infoList?infoList.DB1V.AN96:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
        <li class="libo1">
          <div class="fl libo1-item" >
            <div class="namelf">升温速率：</div>
            <div class="val_data" @click="toCompon(2, 'AN12', 'DB1V', 'AN12','F釜温升速率')"
												@dblclick="Cclick(infoList.DB1V.AN12,'AN12', 'DB1V', 'AN12')"
            >{{infoList?infoList.DB1V.AN12:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">汽阀始开度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN18', 'DB1V', 'AN18','F釜蒸汽阀初始开度')"
												@dblclick="Cclick(infoList.DB1V.AN18,'AN18', 'DB1V', 'AN18')"
            >{{infoList?infoList.DB1V.AN18:0}}</div>
            <div class="danwei">
              %
            </div>
             
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN103', 'DB1V', 'AN103','F釜预测时间')"
												@dblclick="Cclick(infoList.DB1V.AN103,'AN103', 'DB1V', 'AN103')"
            >{{infoList?infoList.DB1V.AN103:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">预测温度：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN115', 'DB1V', 'AN115','F釜预测温度')"
												@dblclick="Cclick(infoList.DB1V.AN115,'AN115', 'DB1V', 'AN115')"
            >{{infoList?infoList.DB1V.AN115:0}}</div>
            <div class="danwei">
              ℃
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">停水升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN37', 'DB1V', 'AN37','F釜停循环水升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN37,'AN37', 'DB1V', 'AN37')"
            >{{infoList?infoList.DB1V.AN37:0}}</div>
            <div class="danwei">
              ℃/m
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">拐点判断常数：</div>
            <div class="fl val_data">
              <div class=" colorl"  @click="toCompon(2, 'AN79', 'DB1V', 'AN79','F折线常数')"
												@dblclick="Cclick(infoList.DB1V.AN79,'AN79', 'DB1V', 'AN79')"
            >{{infoList?infoList.DB1V.AN79:0}}</div>
            <div class=" colorl" style="margin-left:2vw;" @click="toCompon(2, 'AN85', 'DB1V', 'AN85','F折线常数设定')"
												@dblclick="Cclick(infoList.DB1V.AN85,'AN85', 'DB1V', 'AN85')"
            >{{infoList?infoList.DB1V.AN85:0}}</div>
            </div>
        
            <div class="danwei">
            
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫升温速率：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN109', 'DB1V', 'AN109','F釜吹扫升温速率')"
												@dblclick="Cclick(infoList.DB1V.AN109,'AN109', 'DB1V', 'AN109')"
            >{{infoList?infoList.DB1V.AN109:0}}</div>
            <div class="danwei">
              Mpa
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">夹套压力限值：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN91', 'DB1V', 'AN91','F夹套压力限值')"
												@dblclick="Cclick(infoList.DB1V.AN91,'AN91', 'DB1V', 'AN91')"
            >{{infoList?infoList.DB1V.AN91:0}}</div>
            <div class="danwei">
              min
            </div>
          </div>
          <div class="fl libo1-item">
            <div class="namelf">吹扫时间：</div>
            <div class="val_data colorl"  @click="toCompon(2, 'AN97', 'DB1V', 'AN97','F釜吹扫时间')"
												@dblclick="Cclick(infoList.DB1V.AN97,'AN97', 'DB1V', 'AN97')"
            >{{infoList?infoList.DB1V.AN97:0}}</div>
            <div class="danwei">
              %
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="container_foot">
      <div class="nale">
        自动升温
      </div>
    </div>
    <Historical v-if="isHshow" @sendStatus="isClose" :historyname="historyname" :node="nodename" :Lkname="Aname"
			:chName="chName" :infoList="infoList"></Historical>
  </div>
</template>
<script>
	import  index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
export default{
  name: "index",
  components: {
			Historical,
		},
  props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data: () => {
    return {
				isHshow: false,
    }
    },
    mixins: [index],//引入使用index.js
    watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		methods: {
	// 打开趋势组图页面
  qsGroup() {
				this.$router.push({
					path: '/trendGroup'
				});
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {

						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
    }
}
</script>
<style  lang="scss" scoped>
.fyyjqt {
  border: 1px solid #205365;
  margin: 1vh 0.5vw;
  .fl {
    display: flex;
  }
  .box-left {
    width: 14vw;
    height: 43vh;
    border-right: 1px solid #205365;
    // background-color: #2e5e71;
    // opacity: 0.6;
    .li_item1 {
      font-family: MicrosoftYaHei;
      font-size: 2vw;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0vh;
      color: #fefefe;
      height: 11vh;
      line-height: 11vh;
      text-align: center;
    }
    .li_item2 {
      width: 14vw;
      height: 3vh;
      line-height: 3vh;
      background-color: #205365;
    }
    .item1 {
      width: 5vw;
      height: 3vh;
    }
    .item2 {
      width: 4vw;
      height: 3vh;
      color: #5ca4a6;
      font-family: MicrosoftYaHei;
      font-size: 1vw;
    }
    .item3 {
      font-family: MicrosoftYaHei;
      font-size: 1vw;
      width: 4vw;
      height: 3vh;
      color: #5ca4a6;
    }
    .li_item3 {
      .item1 {
        width: 5vw;
        height: 12vh;
        line-height: 12vh;
        color: #5ca4a6;
        font-family: MicrosoftYaHei;
        font-size: 1vw;
        text-align: center;
      }
      .item2 {
        width: 4vw;
        height: 12vh;
        color: #5ca4a6;
        font-family: MicrosoftYaHei;
        font-size: 1vw;
        .titile {
          height: 6vh;
          box-sizing: border-box;
          padding-top: 2vh;
        }
        .titile1 {
          height: 6vh;
          box-sizing: border-box;
          padding-bottom: 2vh;
        }
      }
      .item4 {
        width: 5vw;
        height: 12vh;
        color: #5ca4a6;
        font-family: MicrosoftYaHei;
        font-size: 1vw;
        text-align: center;
        .titile {
          height: 6vh;
          box-sizing: border-box;
          padding-top: 2vh;
        }
        .titile1 {
          height: 6vh;
          box-sizing: border-box;
          padding-bottom: 2vh;
        }
      }

      .item3 {
        font-family: MicrosoftYaHei;
        font-size: 1vw;
        width: 4vw;
        height: 3vh;
        color: #5ca4a6;
      }
    }
    .li_item4 {
      // margin-top: 0.5vh;
      width: 14vw;
      height: 2.5vh;
      line-height: 2.5vh;
      background-color: #205365;
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 1vw;
      color: #5ca4a6;
    }
  }
  .box-right {
    .box-right-top {
      display: flex; /* 使用 Flexbox 布局 */
      justify-content: space-between; /* 水平均匀分布 */
      color: #fefefe;
      width: 81vw;
      .item1 {
        margin-left: 12vw;
        margin-top: 1vh;
        width: 8vw;
        height: 14vh;
        background-image: url("~@/assets/images/醋酐罐.png");
        background-size: 100% 100%;
        color: white; /* 文本颜色 */
        .name {
          margin-top: 2vh;
          width: 8vw;
          font-family: MicrosoftYaHei;
          font-size: 2vh;
          font-weight: 700;
          font-stretch: normal;
          line-height: 1.5vh;
          letter-spacing: 0vh;
          color: #000000;
          text-align: center;
        }
        .value {
          margin-top: 4vh;
          margin-left: 3vw;
          width: 8vw;
          font-family: MicrosoftYaHei;
          font-size: 0.8vw;
          font-weight: 500;
          font-stretch: normal;
          line-height: 1.5vh;
          letter-spacing: 0vh;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
      .item2 {
        margin-left: 8vw;
        margin-top: 1vh;
        width: 8vw;
        height: 14vh;
        background-image: url("~@/assets/images/醋酐罐.png");
        background-size: 100% 100%;
        color: white; /* 文本颜色 */
        .name {
          margin-top: 2vh;
          width: 8vw;
          font-family: MicrosoftYaHei;
          font-size: 2vh;
          font-weight: 700;
          font-stretch: normal;
          line-height: 1.5vh;
          letter-spacing: 0vh;
          color: #000000;
          text-align: center;
        }
        .value {
          margin-top: 4vh;
          margin-left: 3vw;
          width: 8vw;
          font-family: MicrosoftYaHei;
          font-size: 0.8vw;
          font-weight: 500;
          font-stretch: normal;
          line-height: 1.5vh;
          letter-spacing: 0vh;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .box-right-bottom {
      width: 81vw;
      margin-top: 1vh;
      display: flex; /* 使用 Flexbox 布局 */
      justify-content: space-between; /* 水平均匀分布 */

      .item11 {
        margin-left: 5vw;
        width: 8vw;
        height: 26vh;
        background-image: url("~@/assets/images/釜趋势1.png");
        background-size: 100% 100%;
      }
      .value {
        width: 7vw;
        text-align: center;
        margin-top: 9vh;
        font-size: 1vw;
        font-weight: 700;
        cursor: pointer;
      }
      .name {
        width: 7vw;
        margin-top: 2vh;
        text-align: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 1vw;
        font-weight: 700;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vw;
        color: #000000;
      }
      .colorl {
        color: #357eeb;
      }
      .fname {
        width: 6vw;
        margin-top: 3.8vh;
        text-align: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 0.7vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2vh;
        letter-spacing: 0vw;
        color: #fafafa;
      }
    }
  }
  .container-bottom {
    .biaoge-top {
      width: 98.9vw;
      height: 12vh;
      border: 1px solid #205365;
    }
    .lie1 {
      width: 14vw;
      height: 12vh;
      .content1 {
        width: 9vw;
        height: 6vh;
        line-height: 6vh;
        letter-spacing: 0vh;
        color: #5ca4a6;
        border-right: 1px solid #205365;
        text-align: center;
      }
      .itemtl {
        width: 8vw;
        height: 3vh;
        line-height: 3vh;
        text-align: center;
        border-top: 1px solid #205365;
      }
      .name_titl {
        width: 13.95vw;
        height: 3vh;
        line-height: 3vh;
        text-align: center;
        border-top: 1px solid #205365;
        border-right: 1px solid #205365;
        color: #5ca4a6;
      }
    }
    .lie2 {
      margin-top: 0.2vh;
      color: #d9feff;
      width: 14.2vw;
      height: 12vh;
      border-right: 1px solid #205365;

      .bg_item2 {
        width: 14vw;
        height: 3vh;
        line-height: 3vh;
        padding-left: 6vw;
        box-sizing: border-box;
        // text-align: center;
        border-bottom: 1px solid #205365;
        cursor: pointer;
      }
      .bg_item2-2 {
        width: 14vw;
        box-sizing: border-box;
        height: 3vh;
        padding-top:0.5vh;
        // line-height: 3vh;
        text-align: center;
        border-bottom: 1px solid #205365;
        display: flex;
            flex-direction: column; /* 设置为垂直方向的布局 */
            justify-content: space-between; /* 纵向均匀分布 */
      }
      .bg_item2-4 {
        box-sizing: border-box;
        width: 14vw;
        height: 3vh;
        line-height: 3vh;
        padding-left: 5vw;
        border-bottom: 1px solid #205365;
      }

      .bg_item2-5 {
        box-sizing: border-box;
        width: 14vw;
        height: 3vh;
        line-height: 3vh;
        padding-left: 4vw;
        border-bottom: 1px solid #205365;
      }
      .bg_item2-3 {
        width: 14vw;
        height: 3vh;
        line-height: 3vh;
        display: flex;
  // justify-content: space-between; /* 水平均匀分布 */
  // padding-left:0.5vw;
  // padding-right:0.5vw;
  padding-top:0.5vh;
        // border-bottom: 1px solid #205365;
        .widthl{
          width:2.6vw;
          text-align: center;
          height: 2vh;
          line-height: 2vh;
          margin-left:0.8vw;

        }
      }
      .fangkuai {
        width: 3vw;
        height: 2vh;
        margin-left: 5.5vw;
        background-color: #cf284b;
        border-radius: 0vw;
      }
      .fangkuai-1 {
        width: 3vw;
        height: 2vh;
        line-height: 2vh;
        margin-left: 5vw;
        background-color: #cf284b;
        border-radius: 0vw;
      }
      .fangkuai-2 {
        width: 3vw;
        height: 2vh;
        margin-left: 4vw;
        background-color: #cf284b;
        border-radius: 0vw;
      }
    }
  }
  .biaoge-bottom {
    color: #5ca4a6;
    .libo2 {
      width: 14vw;
      height: 26vh;
      line-height: 22vh;
      text-align: center;
      border-right: 1px solid #205365;
    }
    .libo1 {
      width: 14.1vw;
      height: 26vh;
      font-size: 0.8vw;
      // line-height: 22vh;
      display: flex;
            flex-direction: column; /* 设置为垂直方向的布局 */
            justify-content: space-between; /* 纵向均匀分布 */
      text-align: center;
      border-right: 1px solid #205365;
      .libo1-item{
        height: 1.8vh;
        line-height: 1.8vh;
        margin-bottom: 0.5vh;
        margin-top: 0.5vh;
      }
      .namelf{
        box-sizing: border-box;
        width:7vw;
        height: 1.8vh;
        text-align: left;
        padding-left: 1vw;
      }
      .val_data{
        width:5vw;
        cursor: pointer;
      }
      .danwei{
        width:2vw;
        color:#95b5bb
      }
    }
  }
  .container_foot{
    width:99vw;
    height: 2vh;
    line-height: 2vh;
    border-top: 1px solid #205365;
    color: #5ca4a6;
    .nale{
      width: 14vw;
      text-align: center;
      border-right: 1px solid #205365;
    }
  }
  .colorl{
    color: #1e5af1;
  }
}
</style>